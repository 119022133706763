<template>
<div>
	<CRow>
		<CCol sm="6" md="12">
        <CCard>
          <CCardHeader>
            <CRow>
            <CCol col="6" class="text-left"><h4>{{ configName }}</h4></CCol>
            <CCol col="6" class="text-right">
              
              <b-button-group>
                 <b-button variant="warning" v-if="seenBtnWarn">Edit</b-button>
                 <b-button variant="primary" v-on:click="actionRender()" v-if="seenBtnRender">Render</b-button>
                 <b-button variant="success" v-on:click="actionUpdate()" v-if="seenBtnUpdate">Update</b-button>
              </b-button-group>    

            </CCol> 
            </CRow>      
          </CCardHeader>
          <CCardBody> 
         
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>           

            <span v-if="jsoncontent">
              <vue-json-editor v-model="json" :expandedOnStart="true" @json-change="onJsonChange"></vue-json-editor>
            </span> 
            <span v-else>
              <div style="overflow-y: scroll; height: 60vh;">                
                  <b-row>
                    <b-col sm="6">
                      <CodeEditor v-on:change.native="onHtmlChange" v-model="html" :display_language="false" :wrap_code="true"></CodeEditor>
                    </b-col>
                    <b-col sm="6">
                      <div class="text-center">
                        <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                      </div>
                      <div v-html="html"></div>
                    </b-col>
                  </b-row>                                
              </div>
            </span>
           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';
 import vueJsonEditor from 'vue-json-editor';
 import CodeEditor from 'simple-code-editor';

  export default { 
    components: {
      vueJsonEditor, CodeEditor    
    },        
    data() {
      return { 
          spinner:false,
          isDisabledvje:false,
          seenBtnRender: false,
          seenBtnWarn: false,
          seenBtnUpdate:true, 
          content:'',
          configName:'',
          items:[], 
          jsoncontent : true, 
          json:'',
          html:'',
          isAllowed: false,
          seen:false,
          msg :'',
          color:''
        }; 
    },
    created () { 
       this.checkAccessByRole();
       this.getSystemConfigList(this.$route.params.id);      
    },
    methods: { 

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },

      checkAccessByRole() {
        var strRoles  = localStorage.getItem('roles');
        var roles     = strRoles.split(",");
        var isAllowed = roles.includes("ROLE_SUPER_ADMIN") ? true : false;
        this.isAllowed = isAllowed;
        if(!isAllowed) { this.$router.push('/dashboard'); }
      },

      onJsonChange (value) {
         this.content= value;
      },

      onHtmlChange(){
        this.content = this.html;
      },

      actionRender(){
        this.spinner = true;
        var content  = this.html;
        this.html    = content;      
        setTimeout( () =>  this.spinner=false , 1000);  
      },

      actionUpdate(){         
        
        var value  = this.jsoncontent ? JSON.stringify(this.content) : this.html;
        var key    = this.configName;
        let params = { key : key, value : value};

        if(this.content !== ""){
          if(this.isAllowed){ this.updateSystemConfig(params); }
        } else {
          this.msg   = "Config should be change before update action !"; 
          this.color = 'warning'; this.seen  = true;
          setTimeout( () =>  this.seen=false , 5000);  
        }
        
      },

      isValidJson(item) {
        item = typeof item !== "string" ? JSON.stringify(item): item;
        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }
        if (typeof item === "object" && item !== null) {
            return true;
        }
        return false;
      },

      updateSystemConfig: function(params) {
        AuthService.updateSystemConfig(params).then(resp => {   //console.log(resp);
             if(resp.message=='Success'){
                this.msg   = 'Update System Config Successfully'; 
                this.color = 'success';   
              } else {
                this.msg   = !resp.data.message ? "Update System Config Failed!" : resp.data.message; 
                this.color = 'warning';                 
              }
              this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000); 
        }, error => {
          this.loading = false;
        });
      }, 

      getSystemConfigList: function(confName) {
        AuthService.getSystemConfigList(confName).then(resp => { 
          var userData = [] ;
          for (var key in resp) { userData[key] = resp[key]; }
          var isValidJson  = this.isValidJson(userData[confName]);           
          if(isValidJson){ 
            this.json = JSON.parse(userData[confName]); 
            this.jsoncontent = true;            
          } else { 
            this.html = userData[confName];
            this.jsoncontent = false;            
          } 
          this.configName = confName;
        }, error => {
          this.loading = false;
        });
      }    
      
    }
  }
</script>